import React, { Component, useEffect, useState } from 'react'

import Loader from 'react-loader-spinner'
import dataProvider from './myDataProvider'
import AvailableUnits from './components/AvailableUnits/AvailableUnits'
import ApartmentSearch from './components/ApartmentSearch/ApartmentSearch'
import CommunityCarousel from './components/CommunityCarousel/CommunityCarousel'


const CommunitiesHOC = (props) => {

    const [properties, setProperties] = useState(null)
    const [communities, setCommunities] = useState([])

    useEffect(() => {
        async function fetchData() {
            const propertyData = await dataProvider.getList()
            console.log(propertyData)
            setProperties(propertyData)

            
            let cityNames = propertyData.reduce((filtered, property) => {
                if (property.city && !property.realized && !filtered.includes(property.city)) {
                    return filtered.concat(property.city);
                }
                return filtered;
            }, [])
            console.log("City Names: ", cityNames)
            setCommunities(cityNames)
        }

        fetchData()
    }, [])


    const getCurrentProperties = (properties) => properties.filter(p => !p.realized)


    const spinnerStyle = {
        height: '300px',
        flexBasis: 50,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        justifySelf: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'white', //#323232
    }
    


    return(
        <div>
            {properties 
            ? 
            <div>
                <AvailableUnits properties={properties}/>
                <CommunityCarousel />
                <ApartmentSearch properties={getCurrentProperties(properties)} searchCity={props.searchCity}/>
            </div>
            : 
            <div>
                <CommunityCarousel />
                <div style={spinnerStyle}><Loader type="Circles" color="#ff4c4c" height={80} width={80} /></div>
            </div>
        }
        </div>
    )
}

export default CommunitiesHOC