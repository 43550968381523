import React, { Component, useEffect, useState} from 'react'
import './ApartmentSearch.css'
import { Link } from 'react-router-dom'
import Select from 'react-select';
import { companyName, neighborhoods, colorPrimary, Properties, currentProperties, availableRentals } from '../../constants'
//import ReactMapGL, { Marker } from 'react-map-gl'
import { Icon } from 'semantic-ui-react'
import ReactList from 'react-list';
import { validRange } from 'semver';
import ReactHtmlParser from 'react-html-parser';
import { connect } from 'react-redux'
import { fetchProperties, filterProperties } from '../../actions'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
import LeafletMap from '../LeafletMap/LeafletMap'
import dataProvider from '../../myDataProvider'


const ApartmentSearch = (props) => {
    
    const [wrapSelectBoxes, setWrapSelectBoxes] = useState(window.innerWidth <= 700 ? false : true)
    const [properties, setProperties] = useState(null)
    const [filteredProperties, setFilteredProperties] = useState(null)
    const [filters, setFilters] = useState({
        state: { value: null, label: 'State' },
        city: {value: null, label: 'City'},
        bedrooms: { value: null, label: 'Beds' },
        baths: { value: null, label: 'Baths' },
        rent: { value: null, label: 'Rent' },
        type: { value: null, label: 'Type' },
    })
    const [mapPosition, setMapProperties] = useState({
        lat: 40.662855,
        lng: -74.215564,
        zoom: 9,
    })
    

    useEffect(() => {
        console.log(props)
        let label = props.searchCity ? {value: props.searchCity, label: props.searchCity} : {value: null, label: 'City'}
        console.log(label)

        let propertyData = props.properties
        console.log("Property Data: ", propertyData)
        setProperties(propertyData)
        
        //setFilters({ ...filters, city: label })
        handleApartmentSearch({ city: label })
        
        //setFilteredProperties(propertyData)
    }, [properties])

    

    /* resize() {
        let currentWrapSelectBoxes = (window.innerWidth >= 700);
        if (currentWrapSelectBoxes !== wrapSelectBoxes) {
            setState({...state, wrapSelectBoxes: currentWrapSelectBoxes});
        }
    } */



    const handleChangeState = (choice) => {
        handleApartmentSearch({state: choice})
    }

    const handleChangeCity = (choice) => {
        handleApartmentSearch({city: choice})
    }

    const handleChangeBedroom = (choice) => {
        handleApartmentSearch({bedrooms: choice})
    }

    const handleChangeRent = (choice) => {
        handleApartmentSearch({rent: choice})
    }

    const handleChangeBaths = (choice) => {
        handleApartmentSearch({baths: choice})
    }

    const handleChangeType = (choice) => {
        handleApartmentSearch({type: choice})
    }


    const handleApartmentSearch = (term) => {
        let key = Object.keys(term)[0] // city
        let selection = term[key] // Newark
        let list = filters
        list[key] = selection
        console.log("List: ", list)
        let filteredProperties = filterProperties(list)
        console.log("apt search: ", list, filteredProperties)
        setFilters(list)
        setFilteredProperties(filteredProperties)
    }


    const filterProperties = (filter) => {
        console.log("Filter properties: ", properties, filter)
        let temp = properties
        if(!properties) return temp
        Object.keys(filter).map((k) => {
            if (filter[k].value){
                temp = temp.filter((property) => property[k] === filter[k].value)              
            }
        })
        return temp
    }




    const stateOptions = [
        { value: null, label: 'State' },
        { value: 'NJ', label: 'New Jersey' },            
    ];


    const cityOptions = [
        { value: null, label: 'City' },
        { value: 'South Bound Brook', label: 'South Bound Brook/Somerset' }, 
        { value: 'East Orange', label: 'East Orange' }, 
        { value: 'Elizabeth', label: 'Elizabeth' }, 
        { value: 'West New York', label: 'West New York'}, 
        //{ value: 'Newark', label: 'Newark' }, 
        //{ value: 'Irvington', label: 'Irvington' }, 
    ];

    const bedroomOptions = [
        { value: null, label: 'Beds' },
        { value: 'Studio', label: 'Studio' }, 
        { value: 1, label: '1+' }, 
        { value: 2, label: '2+' },
        { value: 3, label: '3+' },
    ];

    const bathOptions = [
        { value: null, label: 'Baths' },
        { value: 1, label: '1+' }, 
        { value: 2, label: '2+' },
        { value: 3, label: '3+' },
    ];

    const typeOptions = [
        { value: null, label: 'Type' },
        { value: 'All', label: 'All' },
        { value: 'Condo', label: 'Condo/Townhome' },
        { value: 'Multi-Family', label: 'Multi-Family' },
        { value: 'Single Family', label: 'Single Family' },
        { value: 'Industrial', label: 'Industrial' },
        { value: 'Retail', label: 'Retail' },


    ];

    const rentOptions = [
        { value: null, label: 'Rent' },
        { value: 1500, label: '$1500+' },
        { value: 1750, label: '$1750+' }, 
        { value: 2000, label: '$2000+' }, 
        { value: 2500, label: '$2500+' },
        { value: 3000, label: '$3000+' },
        // { value: 3500, label: 'Up to $3500' }, 
    ];

    const styles = {
        container: base => ({
          ...base,
          flexBasis: (wrapSelectBoxes ? '15%' : '40%'),
          margin: '15px',
        })
    };


    const renderItem = (index, key) => {
        const property = filteredProperties[index]
        return (<Link to={{
                pathname: '/property/' + property.id,
            }}
            key={property.id}
            className='apartment-card'>
                <div className='apartment-img'>
                    { property.images.length > 0 
                        ? <img src={ property.images[0].src } />
                        : null 
                    }
                </div>
                <div className="apartment-text">
                    <p>{ property.displayName }</p>
                    <p>{ property.city + ", " + property.state }</p>
                    
                    {/* <p>{property.city + ", " + rental.property.state}</p>
                    <p>{bedrooms + " Bed | " + rental.baths + " Bath | " + rental.sqft + " sqft" }</p> */}
                    <div className="card-description">{ ReactHtmlParser(property.description)[0] }</div>
{/*                 <p>{<CurrencyFormat value={rental.rent} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <div>{value}</div>} />}</p>
*/}                </div>
            </Link>
        )
    }
   
    return (
        <>
            <div className="apartment-search" id="search-area">
                <h1>Community Search</h1>
                <div className="search-boxes">
                    {/* <Select
                        value={filters.state}
                        onChange={handleChangeState}
                        options={stateOptions}
                        styles={styles}
                        placeholder="State"
                    /> */}
                    <Select
                        value={filters.city}
                        onChange={handleChangeCity}
                        options={cityOptions}
                        styles={styles}
                        placeholder="City"
                    />
                    <Select
                        value={filters.bedrooms}
                        onChange={handleChangeBedroom}
                        options={bedroomOptions}
                        styles={styles}
                        placeholder="Beds"
                    />
                    <Select
                        value={filters.baths}
                        onChange={handleChangeBaths}
                        options={bathOptions}
                        styles={styles}
                        placeholder="Baths"
                    />
                    <Select
                        value={filters.rent}
                        onChange={handleChangeRent}
                        options={rentOptions}
                        styles={styles}
                        placeholder="Rent Max"
                    />
                    <Select
                        value={filters.type}
                        onChange={handleChangeType}
                        options={typeOptions}
                        styles={styles}
                        placeholder="Type"
                    />
                </div>               
            </div>


            <div className="search-results">
                {/* Display spinner while data fetches */}
                {filteredProperties === null ? 
                    <div className="results-spinner"><Loader type="Circles" color="#ff4c4c" height={80} width={80} /></div>
                :
                /* Display message if there are no results from the search */
                ((filteredProperties === undefined || filteredProperties.length == 0) 
                    ?   <div className="no-results"><p>No Results...</p></div>
                    :
                        <div className="apartments-list">
                            <div style={{overflow: 'auto', maxHeight: 500}}>
                                <ReactList
                                    itemRenderer={renderItem}
                                    length={filteredProperties.length}
                                    type='simple'
                                    initialIndex={0}
                                />
                            </div>
                        </div>
                )}

                <LeafletMap properties={filteredProperties} />

            </div>
        </>
    )
}


export default ApartmentSearch;