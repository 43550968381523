import React, { Component } from 'react'
import './Home.css';
import Backdrop from '../../components/Backdrop/Backdrop';
import { companyName, neighborhoods } from '../../constants'
import Carousel from "react-multi-carousel";
import Title from '../../elements/Title/Title';
import { Link } from 'react-router-dom'

import CommunityCarousel from '../../components/CommunityCarousel/CommunityCarousel'

import axios from 'axios';
import XMLParser from 'react-xml-parser'
import "react-multi-carousel/lib/styles.css";
import ReactList from 'react-list';
import TestAPI from '../../TestAPI';
import PropertiesHOC from '../../PropertiesHOC'




class Home extends Component {
    state = {
        sideDrawerOpen: false,
    }

    // jump to top of page upon mounting
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    
    drawerToggleClickHandler = () => {
        console.log('Drawer clicked!')
        this.setState((prevState) => {
        return { sideDrawerOpen: !prevState.sideDrawerOpen }
        })
    }
    
    backdropClickHandler = () => {
        this.setState({ sideDrawerOpen: false })
    }
    
    drawerLinkClickHandler = () => {
        this.setState({ sideDrawerOpen: false })
    }


    
    render() {
        document.title = companyName;
        let backdrop;
    
        if(this.state.sideDrawerOpen){
            backdrop = <Backdrop click={this.backdropClickHandler} />
        }

    
        return(

            <div className="home">
                <div className="home-banner">
                    <div className='home-banner-text'>
                        <p>Community focused</p>
                        <p>management.</p>
                        <Link to='/communities'><div className="home-banner-text-button">Find Your Home</div></Link>
                    </div>
                </div>


                <div className="home-intro">
                    {/* <p>At {companyName}, we provide luxury housing in New Jersey's Gold Coast, the area running along the Hudson river, across from Manhattan.</p> */}
                    <p>Finding an apartment shouldn't be a headache, that's why our mission is to make the process as painless as possible. We provide friendly, attentive service to ensure that both current and prospective tenants are satisfied with the job we're doing.</p>
                    <p>If you're looking for a new place to live, browse our current availabilites and if you find a match, please contact us for a tour or send in an application. We're excited for you to join our growing community!</p>
                </div>


                <PropertiesHOC />
                {/* <AvailableUnits /> */}

                    
                {/* <ApartmentSearch /> */}
            
            </div>
        )
    }
}


export default Home