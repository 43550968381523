import React, { Component, useEffect } from 'react'
import ValuesBanner from '../../components/ValuesBanner/ValuesBanner'

import Title from '../../elements/Title/Title'



import './About.css'

class About extends Component {
    // jump to top of page upon mounting
    componentDidMount() {
        window.scrollTo(0, 0);
    }



    render() {
        document.title = "About Us";

        return(
            <div id="main" className="about-main" id="about-banner">

            <div className="about-banner">
                <div className='about-banner-text'>
                    
                </div>
            </div>

            <div className="who-we-are">
                <Title text="What We Do" />
                <p>SKG Capital is a private real estate investment firm that owns and manages luxury multifamily assets in major Northeast markets, focused on transit-oriented living and New Jersey’s Gold Coast, the stretch of waterfront running along the Hudson River overlooking breathtaking Manhattan views.</p>
            </div>

            
            <div className="services-offered">
                <div className="service">
                    <div className="service-title"><h1>Property Management</h1></div>
                    <p>Our Maintenance and Construction team is primarily responsible for fixing the damage and replacing equipment for the efficient operation of our communities. Our team facilitates and corrects all repairs associated with a specific work order, including routine and/or minor emergency repairs. Construction consists of building/moving and/or demolishing structures, excavations, landscaping, site improvements, drainage systems and/or modifications of such facilities. Our crew includes Service Managers, Field Supervisors, Plumbers, Electricians, Service Technicians, and others! If you are seeking a position that will allow you to bring your hands-on experience and "can do" attitude to the field  - contact us today!</p>
                </div>
                <div className="service">
                <div className="service-title"><h1>Maintenance and Construction</h1></div>
                    <p>Our Property Management team is responsible for the control and operation of our communities, personal property, equipment, and physical assets. The many duties of the Property Management team include rent collection, superior customer service, maintenance and repairs, knowledge of landlord-tenant laws, along with managing a budget and maintaining records. Our team consists of Community Managers, Leasing Professionals, Customer Service Representatives and many more! If you are seeking a position that will allow you to be innovative and forward thinking while working in a fast pace environment  - we would love to speak with you!</p>
                </div>
                <div className="service">
                <div className="service-title"><h1>Asset Management</h1></div>
                    <p>Our Accounting and Asset Management team prepares and examines financial records while verifying accuracy and works to ensure that our communities are financially sounds. The team performs overviews of the financial operations in order to help it run efficiently while developing, operating, maintaining, upgrading, and disposing of assets in the most cost-effective manner. Our team includes Senior Accountants, Account Receivables and Payables and many more! If you are seeking a position that will allow you to crunch numbers while being organized and trustworthy  - we want to hear from you!</p>
                </div>
            </div>

            <ValuesBanner />

            </div>
        )
    }
}

export default About

