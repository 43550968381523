import React, { Component, useEffect } from 'react'
import './ValuesBanner.css'
import { currentProperties, Properties } from '../../constants'
import { Icon } from 'semantic-ui-react';
import Title from '../../elements/Title/Title';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
    AccordionItemState,
} from 'react-accessible-accordion';
 


const values = [
    {title: 'Integrity', description: 'We strive to always be open and honest with our tenants. Our goal is to build a trusting relation between tenant and manager.', icon: 'handshake outline'},
    {title: 'Commitment', description: 'We\'re committed to providing our tenants with the best rental experience possible.', icon: 'certificate'},
    {title: 'Accessibility', description: 'Our door is always open for both current and prospective residents to discuss anything that\'s on their minds.', icon: 'phone'},
    {title: 'Service', description: 'We always strive to provide our tenants with the best living experience possible. Please contact us to discuss any questions or concerns.', icon: 'universal access'},
    {title: 'Vision', description: 'We aim to stay ahead of the times and provide modern apartment amenities to our tenants.', icon: 'eye'},
    {title: 'Leadership', description: 'We lead by example and expect our building managers to meet our standard of excellence.', icon: 'user circle'},
]

const ValuesBanner = props => {

    

    
        return(
            <div className="values-section">
                <Title text="Our Values" />
                <Accordion className="values-accordion" allowZeroExpanded={true} >
                    {values.map((value) => {
                        return (
                            <AccordionItem className="accordion-item">
                                <AccordionItemState>
                                {({ expanded }) => (
                                    <AccordionItemHeading>
                                        <AccordionItemButton className={expanded ? 'accordion-icon-active' : 'accordion-icon-inactive'}>
                                            <Icon aria-hidden="false" name={value.icon} size="massive" /* huge */ /> 
                                            <h3 className={expanded ? 'accordion-item-title-active' : 'accordion-item-title-inactive'}>{value.title}</h3>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                )}
                            </AccordionItemState>
                                <AccordionItemPanel>
                                    <p>
                                        {value.description}
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                        )
                    })}
                </Accordion>
            </div>
        )
}

export default ValuesBanner
