
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import './Footer.css'
import { companyName } from '../../constants'


const Footer = () => {

    let d = new Date(), currentYear = d.getFullYear();

    return(
        <footer className='footer'>
            <div className="footer-row">
                <div className='contact-info'>
                    <p>{companyName}</p>
                    <p><a href="https://goo.gl/maps/TEgoNotN68pACU3CA" target="_blank" rel="noopener noreferrer">315 Ridgedale Avenue, Unit 370 | East Hanover, NJ 07936</a></p>
                    <p><a href="tel:973-585-6108">(973) 585-6108</a></p>
                    <p><a href="tel:973-585-6110">(973) 585-6110 (fax)</a></p>
                    <p><a href="mailto:shiva.investors@gmail.com">
                    shiva.investors@gmail.com</a></p>
                </div>
                <div className='sitemap'>
                    <p><Link to='/'>Home</Link></p>
                    <p><Link to='/communities'>Communities</Link></p>
                    <p><Link to='/about'>About</Link></p>
                    <p><Link to='/careers'>Careers</Link></p>
                    <p><Link to='/residents'>Residents</Link></p>
                    <p><Link to='/contact'>Contact</Link></p>
                    <p><a href='https://d3gqbe606j5x47.cloudfront.net' target="_blank" rel="noopener noreferrer">Admin</a></p>
                </div>
            </div>
            <small style={{alignSelf: 'center', paddingTop: '1em'}}>Copyright &copy; {currentYear}, {companyName}</small>
        </footer>
    )
}

export default Footer

