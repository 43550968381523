

import React, { Component, useEffect } from 'react'
import { Link, animateScroll as scroll } from "react-scroll";
import { Icon } from 'semantic-ui-react'
import './ImageBanner.css'



const ImageBanner = (props) => {

    return(
        <div className="image-banner" style={{height: props.height}}>
            <div className={props.wrapper}>
                <img src={props.img}/>
            </div>

            <div className="cover">
                <h2>{props.title}</h2>

                { props.subtitle ? 
                    <p>{props.subtitle}</p>  
                : null }

                { props.other ?
                    <div className="about-banner-top">{props.other}</div>
                : null }


                { props.icon ? 
                    <div className="down-icon">
                        <Icon aria-hidden="false" name="angle down" size="huge"><Link
                            to="aboutInfo"
                            spy={true}
                            smooth={true}
                            offset={-150}
                            duration= {500}
                            margin={0}
                        /> </Icon>
                    </div>
                : null}
            </div>

        </div>     
    )
}

ImageBanner.defaultProps = {
    img: "/backgrounds/jersey_city.jpg",
    height: '100vh',
    wrapper: 'purple',
    title: "",
};


export default ImageBanner;