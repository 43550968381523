import React, { Component, useEffect } from 'react'
import './Careers.css'
import ImageBanner from '../../components/ImageBanner/ImageBanner';
import { Properties, companyName } from '../../constants'
import Application from '../../components/Application/Application'


const Careers = (props) => {

    document.title = "Careers";

    const banner= {
        width: '100vw',
        height: "calc(" + "70vh - 100px" + ")",
        backgroundImage: "url(" + "/backgrounds/careers.jpg" + ")",
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        backgroundPosition: 'center top',
    }

    return(
        <div className="careers-container">
            <div className="careers-banner" style={banner} >
                <div className="careers-banner-overlay">
                    <h1>Join our Team</h1>
                </div>
            </div>
            <div className="careers-info-wrapper">
                <div className="careers-intro">
                    {/* <h1>{companyName} is hiring!</h1> */}
                    <p>We're currently hiring individuals skilled in residental property management and building mantainence, who share our passion for providing exceptional service.</p>
                    <p>If you're excited to begin your career with {companyName}, view our open positions, send us an application, and we'll get in contact with you.</p>
                    {/* <div id="open-positions-button">View Open Positions</div> */}
                </div>
            </div>

            <Application />
        </div>
    )
}

export default Careers;