export const companyName = "SKG Management"

export const colorPrimary = '#ff4c4c'


export const neighborhoods = [{title: 'South Bound Brook', name: 'somerset', status: 'active'}, {title: 'East Orange', name: 'east_orange', status: 'active'}, {title: 'Elizabeth', name: 'elizabeth', status: 'active'}, {title: 'West New York', name: 'west_new_york', status: 'active'}, {title: 'Newark', name: 'newark', status: 'inactive'}, {title: 'Jersey City', name: 'jersey_city', status: 'inactive'}, {title: 'Irvington', name: 'irvington', status: 'inactive'}]


export const applicationProcess = ['<a target="_blank" rel="noopener noreferrer" href="https://shivainvestors.managebuilding.com/Resident/apps/rentalapp/">Apply Online</a>',
 '$45.00 Application Fee Per Applicant',
 'Email: shiva.investors@gmail.com  &  Fax: 973-585-6110',
 'Income, employment and landlord verification will be done after the application is submitted.',
 'Serious inquiries only',]
