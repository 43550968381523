import React, { Component, useEffect, useState } from 'react';
import '../App.css'
import Home from './Home/Home';
import About from './About/About';

import Contact from './Contact/Contact';
import Toolbar from '../components/Toolbar/Toolbar';
import SideDrawer from '../components/SideDrawer/SideDrawer';
import Backdrop from '../components/Backdrop/Backdrop';
import Footer from '../components/Footer/Footer'
import Property from './Property/Property'
import Apartments from './Apartments/Apartments'
import Residents from './Residents/Residents'
import Careers from './Careers/Careers'


import { ParallaxProvider } from 'react-scroll-parallax';
import { BrowserRouter as Router, Switch, Route, Redirect  } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { fetchProperties } from "../actions/index";


const App = (props) => {

  /* const properties = useSelector(state => state.properties.properties);
  const dispatch = useDispatch();
 */
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false)
  const drawerToggleClickHandler = () => {
    console.log('Drawer clicked!')
    setSideDrawerOpen(!sideDrawerOpen)
  }

  const backdropClickHandler = () => {
    setSideDrawerOpen(false)
  }

  const drawerLinkClickHandler = () => {
    setSideDrawerOpen(false)
  }

  
  const backdrop = () => {
    if(sideDrawerOpen){
      return <Backdrop click={backdropClickHandler} />
    }
  }

  /* useEffect(() => {
    //dispatch(fetchProperties())
  },[])  */

  return (
    <ParallaxProvider>
    <Router>
        {/*console.log("App properties: ", properties)*/}
        <div className="App">
          <Toolbar drawerClickHandler={drawerToggleClickHandler} />
          <SideDrawer show={sideDrawerOpen} drawerLinkClickHandler={drawerLinkClickHandler} />
          {backdrop}
          <Switch>
            <Route path="/" exact component={Home}/> 
            <Route path="/about" exact component={About}/>

            {/* random key - refresh page on plain route navigate */}
            <Route path="/communities" exact render={(props) => <Apartments {...props} keyProp={props} key={Math.random()}/>}/>
            <Route path="/communities/:city" component={Apartments} />

            <Route path="/property/:property" exact component={Property}/>
            <Route path="/contact" component={Contact}/>
            <Route path="/residents" component={Residents}/>
            <Route path="/careers" component={Careers}/>
                          
            <Route render={() => <Redirect to={{pathname: "/"}} />} />
          </Switch>
          <Footer />
        </div>
    </Router>
    </ParallaxProvider>

  );
}

// <Home />
// <Route path="/news" component={News}/>
// <Route path="/careers" component={Careers}/>

export default App;
