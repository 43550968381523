import React, { Component, useEffect } from 'react'
import './Apartments.css'
import ApartmentSearch from '../../components/ApartmentSearch/ApartmentSearch'
import PropertiesHOC from '../../PropertiesHOC'
import CommunitiesHOC from '../../CommunitiesHOC'


const Apartments = (props) => {
    const searchCity = props.match.params.city

    return (
        <div className="apartments-container">
            <div className="apartments-banner">
                <div className='apartments-banner-text'>
                    <h1>Find Your Dream Home Today.</h1>
                </div>
            </div>
            <CommunitiesHOC searchCity={searchCity}/>
            {/* <ApartmentSearch searchCity={searchCity} /> */}
        </div>
    )
}

export default Apartments;