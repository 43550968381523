

import React, { Component } from 'react'
import Carousel from "react-multi-carousel";
import { companyName, neighborhoods } from '../../constants'
import Title from '../../elements/Title/Title';


const CommunityCarousel = (props) => {

    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4,
          slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1024, min: 550 },
          items: 2,
          slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 550, min: 0 },
          items: 2,
          slidesToSlide: 1 // optional, default to 1.
        }
    };

    return(
        <>
            <div className="neighborhoods">
                <Title text="Our Communities" top={40} bottom={25}/>
            </div>
            <div style={{borderBottom: '1px solid black'}}>
                <Carousel
                        swipeable={false}
                        draggable={false}
                        responsive={responsive}
                        ssr={true} // means to render carousel on server-side.
                        infinite={true}
                        keyBoardControl={true}
                        customTransition="all .5"
                        transitionDuration={500}
                        containerClass="carousel-container"
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                        deviceType={props.deviceType}
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px"
                        >
                        {neighborhoods.map((neighborhood) => {
                            return (
                                <div key={neighborhood.title} className="neighborhood-slide">
                                    <img src={"/Neighborhoods/" + neighborhood.name + ".jpg"}/>
                                    <div className="bottom-left">{neighborhood.title}</div>
                                </div>
                            )
                        })}  
                </Carousel>
            </div>
        </>
    )
}

export default CommunityCarousel



