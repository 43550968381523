import React, { Component, useEffect } from 'react'
import './Residents.css'
import ImageBanner from '../../components/ImageBanner/ImageBanner';
import { Link } from 'react-router-dom'
import { companyName } from '../../constants';



const Residents = (props) => {
    return(
        <>
            <div className="residents-banner">
                <div className='residents-banner-text'>
                    <h1>We're Here to Help!</h1>
                    {/* <div className="residents-banner-buttons">
                        <a href="https://shivainvestors.managebuilding.com/Resident/portal/login" to="contact/"><div className="residents-banner-text-button">Current Residents Portal</div></a>
                        <Link to="apartments/"><div className="residents-banner-text-button">I'm Looking for an Apartment</div></Link>
                    </div> */}
                </div>
            </div>
            <div className='residents-info'>
                <div id="resident-info-text">
                    <h1>Current Residents</h1>

                    <p>Thank you for being a member of our community; we're here to assist you with anything you need!</p>
                    <p>To pay rent or submit a maintenance request, see the links below. For other inquiries, please refer to our contact page.</p>

                    <div className='resident-info-buttons'>
                        <a target="_blank" rel="noopener noreferrer" href="https://shivainvestors.managebuilding.com/Resident/portal/login">Submit Maintenance</a>
                        <a target="_blank" rel="noopener noreferrer" href="https://shivainvestors.managebuilding.com/Resident/portal/login">Pay Rent</a>
                    </div>
                </div>
                <div id="resident-info-img"/>
                <div id="resident-info-img"/>
                <div id="resident-info-text">
                    <h1>Prospective Residents</h1>
                    <p>Looking for your new home? We're excited to show you the benefits of choosing {companyName}!</p>
                    <p>Please view the properties under our management, and submit an application if you find the right fit for you.</p>
                    <div className='resident-info-buttons'>
                        <Link to="/communities">View Properties</Link>
                        <a target="_blank" rel="noopener noreferrer" href="https://shivainvestors.managebuilding.com/Resident/apps/rentalapp/">Apply Online</a>
                    </div>
                </div>
            </div>
            
        </>
    )
}

export default Residents;