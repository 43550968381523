import React, { Component, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './Toolbar.css'
import '../SideDrawer/DrawerToggleButton'
import DrawerToggleButton from '../SideDrawer/DrawerToggleButton'
import { Switch, useLocation } from 'react-router-dom'
import { currentProperties, Properties, neighborhoods } from '../../constants'
import dataProvider from '../../myDataProvider'



const Toolbar = props => {

        const [locations, setLocations] = useState([])
        
        // Make the toolbar semi-transparent if we're on the Home screen
        let location = useLocation()
        useEffect(() => {
            let header = document.querySelector("header");
            let classes = header.classList;
            if(location.pathname === '/'){
                classes.remove("toolbar");
                classes.add("transparent");
            }else{
                classes.remove("transparent");
                classes.add("toolbar");
            }

            async function fetchData() {
                const locationData = await dataProvider.getLocations()
                console.log("Locations: ", locationData)
          
                const unique = [...new Set(locationData.Items.map(item => {
                    return (
                        item.loc.M.city.S 
                    );
                }))]                
                setLocations(unique)
            }

            fetchData()
        }, [])



        return(
            <header className="toolbar">
                <nav className="toolbar_nav">
                    <div className="toolbar_toggle-button">
                        <DrawerToggleButton click={props.drawerClickHandler}/>
                    </div>
                    <div className='toolbar_logo'>
                        <Link to='/'><img src="/logos/SKGCapitalLogo.png" width="200" height="75" /></Link>
                    </div>
                    <div className='spacer' />
                    <div className='toolbar_nav-items'>
                        <ul className='toolbar_nav-items'>

                            <div className="dropdown">
                                <li><Link to='/communities/' className="navLink">Communities</Link></li>
                                <div className="dropdown-content">
                                    {locations.map(loc =>
                                        <a key={loc} href={'/communities/' + loc + "#search-area"}>{loc}</a>
                                    )}
                                </div>
                            </div>


                            <div className="dropdown">
                                <li><Link to='/about' className="navLink">About</Link></li>
                                <div className="dropdown-content">
                                   {/*  <a href="/about#about-banner">Who We Are</a>
                                    <a href="/about#investment-platform">Our Investment Platform</a>
                                    <a href="/about#leadership">Meet Our Leadership</a> */}
                                </div>
                            </div>

                            
                            <li><Link to='/careers' className="navLink">Careers</Link></li>

                            <li><Link to='/contact' className="navLink">Contact</Link></li>


                            <div className="dropdown">
                                <Link to='/residents' className="navLink dropbtn">Residents</Link>
                                <div className="dropdown-content">
                                    <a target="_blank" rel="noopener noreferrer" href="https://shivainvestors.managebuilding.com/Resident/portal/login">Resident Portal</a>
                                </div>
                            </div>
                        </ul>
                    </div>
                </nav>
            </header>
        )
}

export default Toolbar
