import React, { Component } from 'react'
import { Parallax, ParallaxBanner, ParallaxProvider } from 'react-scroll-parallax';
import emailjs from 'emailjs-com';
import HorizonalRule from '../../elements/HorizonalRule';
import './Contact.css';
//import 'bootstrap/dist/css/bootstrap.css';
import { companyName, colorPrimary } from '../../constants'



import { Form, Col, Button} from 'react-bootstrap'


class Contact extends Component {
    state = {
        formResponse: ''
    }

    
    // jump to top of page upon mounting
    componentDidMount() {
        window.scrollTo(0, 0);
    }


    handleSubmitForm = (event) => {
        event.preventDefault()
        emailjs.sendForm('outlook', 'contact_form', event.target, 'user_WsFYHXgjX0cR2oHq2ybGY')
        .then((result) => {
            console.log("Contact form submitted successfully: ", result.text);
            this.setState(prevState => ({
                ...prevState,
                formResponse: 'Message sent successfully'
            }))
        }, (error) => {
            console.log("Contact form submission error: ", error.text);
            this.setState(prevState => ({
                ...prevState,
                formResponse: 'Something went wrong when sending your message: ' + error.text
            }))
        });
        event.target.reset();
    }

  
    render() {
        document.title = "Contact Us";

        return(
            <div className="contact-container">
                <div className="form-container" >
                    <div className="contact-text" >
                        <h1>Contact Us</h1>
                        <p>Please complete the form below and we'll get back to you as soon as possible.</p>
                    </div>
                    <Form className="form" onSubmit={ this.handleSubmitForm }>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridFirstName">
                                <Form.Label>First Name*</Form.Label>
                                <Form.Control required type="text" name="first_name" placeholder="John" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridLastName">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control type="text" name="last_name" placeholder="Doe" />
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label>Email*</Form.Label>
                                <Form.Control required type="email" name="email" placeholder="jdoe@gmail.com" />
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridPhone">
                                <Form.Label>Phone</Form.Label>
                                <Form.Control type="phone" name="phone" placeholder="(555) 555-5555 " />
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridSubject">
                                <Form.Label>Subject*</Form.Label>
                                <Form.Control required name="subject" type="text" />
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridComments">
                                <Form.Label>Comments*</Form.Label>
                                <Form.Control required as="textarea" name="comments" rows="3" style={{resize:"vertical", maxHeight:"200px", minHeight:'100px'}} />
                            </Form.Group>
                        </Form.Row>


                        {this.state.formResponse ? 
                            <p className="form-response">{this.state.formResponse}</p>
                        : null}


                        <Button variant="primary" style={{background: colorPrimary, padding: '5px 40px'}} type="submit">
                            Submit
                        </Button>
                    </Form>
                </div>

                <div className="contact-div">
                    <h1>Contact Information</h1>
                    <HorizonalRule />
                    <div style={{margin: '0 0 50px 0'}}>
                        <p className='address'>{companyName}, LLC</p>
                        <p className='address'>315 Ridgedale Avenue, Unit 370</p>
                        <p className='address'>East Hanover, NJ 07936</p>
                        <p><a href="tel:973-585-6108">(973) 585-6108</a></p>
                        <p><a href="tel:973-585-6110">(973) 585-6110</a> (fax)</p>
                        <p><a href="mailto:shiva.investors@gmail.com">
                            shiva.investors@gmail.com</a></p>
                    </div>
                </div>
            </div>
        );
    }
  
    onNameChange(event) {
      this.setState({name: event.target.value})
    }
  
    onEmailChange(event) {
      this.setState({email: event.target.value})
    }
  
    onMessageChange(event) {
      this.setState({message: event.target.value})
    }
  
    handleSubmit(event) {
        
    }
  }
  
  export default Contact;