
const FETCH_PROPERTIES_REQUEST = 'FETCH_PROPERTIES_REQUEST'
const FETCH_PROPERTIES_SUCCESS = 'FETCH_PROPERTIES_SUCCESS'
const FETCH_PROPERTIES_FAILURE = 'FETCH_PROPERTIES_FAILURE'
const FETCH_PROPERTY_REQUEST = 'FETCH_PROPERTY_REQUEST'
const FETCH_PROPERTY_SUCCESS = 'FETCH_PROPERTY_SUCCESS'
const FETCH_PROPERTY_FAILURE = 'FETCH_PROPERTY_FAILURE'
const FILTER_PROPERTIES = 'FILTER_PROPERTIES'




const initialState = {
    isFetching: false,
    properties: [],
    filteredProperties: [],
    selectedProperty: null,
    error: ''
}


const propertyReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_PROPERTIES_REQUEST:
            console.log("Request")
            return {
                ...state,
                isFetching: true
            }
        case FETCH_PROPERTIES_SUCCESS:
            console.log("Success")

            return {
                isFetching: false,
                properties: action.payload,
                error: ''
            }
        case FETCH_PROPERTIES_FAILURE:
            console.log("Failure")
            return {
                isFetching: false,
                properties: [],
                error: action.payload
            }
        case FETCH_PROPERTY_REQUEST:
            console.log("Request")
            return {
                ...state,
                isFetching: true
            }
        case FETCH_PROPERTY_SUCCESS:
            console.log("Success")

            return {
                isFetching: false,
                selectedProperty: action.payload,
                error: ''
            }
        case FETCH_PROPERTY_FAILURE:
            console.log("Failure")

            return {
                isFetching: false,
                selectedProperty: null,
                error: action.payload
            }
        case FILTER_PROPERTIES:
            console.log('Filtering properties', action.payload)
            return {
                ...state,
                filteredProperties: action.payload
            }
        default:
            return state;
    }
}

export default propertyReducer;