import React, { Component } from 'react'
import { Parallax, ParallaxBanner, ParallaxProvider } from 'react-scroll-parallax';
import emailjs from 'emailjs-com';
import HorizonalRule from '../../elements/HorizonalRule';
import './Application.css';
//import 'bootstrap/dist/css/bootstrap.css';
import { companyName, colorPrimary } from '../../constants'



import { Form, Col, Button} from 'react-bootstrap'


class Application extends Component {
    state = {
        formResponse: ''
    }

    
    // jump to top of page upon mounting
    componentDidMount() {
        window.scrollTo(0, 0);
    }


    handleSubmitForm = (event) => {
        event.preventDefault()
        emailjs.sendForm('gmail', 'job_application_test', event.target, 'user_dRA1rm1GsXjXdzw6yapTG')
        .then((result) => {
            console.log("Contact form submitted successfully: ", result.text);
            this.setState(prevState => ({
                ...prevState,
                formResponse: 'Message sent successfully'
            }))
        }, (error) => {
            console.log("Contact form submission error: ", error.text);
            this.setState(prevState => ({
                ...prevState,
                formResponse: 'Something went wrong when sending your message: ' + error.text
            }))
        });
        event.target.reset();
    }

  
    render() {
        document.title = "Contact Us";

        return(
            <div className="application-container">
                <div className="application-form-container" >
                    <div className="application-text" >
                        <h1>Apply to {companyName}</h1>
                        <p>Please complete the form below and we'll get back to you as soon as possible!</p>
                    </div>
                    <Form className="form" onSubmit={ this.handleSubmitForm }>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridFirstName">
                                <Form.Label>First Name*</Form.Label>
                                <Form.Control required type="text" name="first_name" placeholder="John" />
                            </Form.Group>
                            
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridLastName">
                                <Form.Label>Last Name*</Form.Label>
                                <Form.Control type="text" name="last_name" placeholder="Doe" />
                            </Form.Group>
                            
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label>Email*</Form.Label>
                                <Form.Control required type="email" name="email" placeholder="jdoe@gmail.com" />
                            </Form.Group>
                            
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridPhone">
                                <Form.Label>Phone</Form.Label>
                                <Form.Control type="phone" name="phone" placeholder="(555) 555-5555 " />
                            </Form.Group>
                            
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridPhone">
                                <Form.Label>Applying for position*</Form.Label>
                                <Form.Control as="select" name="position" defaultValue="100">
                                {/* <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option> */}
                                </Form.Control>
                            </Form.Group>
                            
                        </Form.Row>


                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridResumeUpload">
                                <Form.File.Label>Upload Resume*</Form.File.Label>
                                <Form.Control
                                    required
                                    name="resume"
                                    id="resume"
                                    type="file"
                                    accept=".pdf, .doc, .docx"
                                    onChange={this.addFile}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                            </Form.Group>
                        </Form.Row>

                        {/* <input type="file" name="resume" />  */}
                        {/* To run this example create a template with the attachment of type Form file upload, and set the parameter name to "my_file". */}


                        {this.state.formResponse ? 
                            <p className="form-response">{this.state.formResponse}</p>
                        : null}


                        <Button variant="primary" style={{background: colorPrimary, padding: '5px 40px', margin: '10px 0 0 0'}} type="submit">
                            Submit
                        </Button>
                    </Form>
                </div>

                
            </div>
        );
    }
  
    onNameChange(event) {
      this.setState({name: event.target.value})
    }
  
    onEmailChange(event) {
      this.setState({email: event.target.value})
    }
  
    onMessageChange(event) {
      this.setState({message: event.target.value})
    }
  
    handleSubmit(event) {
        
    }
  }
  
  export default Application;