import React, { Component, useEffect, useState } from 'react'
import FlexView from 'react-flexview';
import { Link } from 'react-router-dom'
import HorizonalRule from '../../elements/HorizonalRule'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Properties, colorPrimary,  availableRentals, applicationProcess } from '../../constants'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import ReactHtmlParser from 'react-html-parser';
import Gallery from 'react-grid-gallery';
import './Property.css'
import Title from '../../elements/Title/Title'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import CurrencyFormat from 'react-currency-format';
import { Icon } from 'semantic-ui-react';
import ReactList from 'react-list';
import { useDispatch, useSelector } from "react-redux";
import { fetchPropertyById } from '../../actions'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
import dataProvider from '../../myDataProvider'


import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
    AccordionItemState,
} from 'react-accessible-accordion';



const Property = (props) => {

    const [loadedImages, setLoadedImages] = useState([])
    const [images, setImages] = useState([])

    const [property, setProperty] = useState(null)

    useEffect(() => {
        window.scrollTo(0, 0);
        console.log(props.match.params.property)

        async function fetchData() {
            const propertyData = await dataProvider.getOne(props.match.params.property)
            console.log(propertyData)
            setProperty(propertyData)
        }

        fetchData()
    }, [])


    const onLoad = (img) => {
        setLoadedImages([...loadedImages, img])
    }

    const banner= {
        width: '100vw',
        height: "calc(" + "100vh - 100px" + ")",
        backgroundImage: property ? "url(" + property.images[0].src + ")" : null,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        cursor: 'pointer',
    }


    const [isOpen, setIsOpen] = useState(false)
    const [photoIndex, setPhotoIndex] = useState(0)


    const getUnitTypeString = (unit) => {
        let type = unit.unitTypeExp
        return (type.beds + " Bed | " + type.baths + " Bath | " + type.sqft + " sqft")
    }


    return(
        <div style={{background: "#F0F0F0"}}>
        {property ? 
        <div className="property-container">
            <div className="property-banner" style={banner} onClick={() => setIsOpen(true)} >
                            <div className="property-banner-overlay">
                                <h1>{ property.displayName }</h1>
                                <h3>{ property.city + ', ' + property.state }</h3>

                                <p>Click to view gallery</p>
                            </div>
                            { isOpen && (
                                <Lightbox
                                    mainSrc={ property.images[photoIndex].src }
                                    nextSrc={ property.images[(photoIndex + 1) % property.images.length].src }
                                    prevSrc={ property.images[(photoIndex + property.images.length - 1) % property.images.length].src }
                                    onCloseRequest={ () => setIsOpen(false) }
                                    onMovePrevRequest={ () =>
                                    setPhotoIndex(
                                        (photoIndex + property.images.length - 1) % property.images.length
                                    )}
                                    onMoveNextRequest={ () =>
                                    setPhotoIndex(
                                        (photoIndex + 1) % property.images.length
                                    ) }
                                />
                            ) }
                        </div>
                        <div className="property-info-wrapper">
                            <div className="property-box" >
                                <div id="property-box-text">
                                    { ReactHtmlParser(property.description) }
                                </div> 


                                <div className="property-box-lists">
                                    <div className="property-box-list">
                                        <p>Property Features</p>
                                        { ReactHtmlParser(property.features) }
                                    </div> 
                                    <div className="property-box-list">
                                        <p>Local Attractions</p>
                                        { ReactHtmlParser(property.attractions) }
                                    </div> 
                                </div>

                                <div className="property-box-lists">
                                    <ul className="property-box-list">
                                        <p>Move In Requirements</p>
                                        { ReactHtmlParser(property.requirements) }
                                    </ul> 
                                    <ul className="property-box-list">
                                        <p>Application Process</p>
                                        { ReactHtmlParser(property.applicationProcess) }

                                        {/*applicationProcess.map((str) => <li key={str}>{ReactHtmlParser(str)}</li>)*/}
                                    </ul>
                                </div>
                            </div>

                        <div className="current-availabilities">
                            <Title text="Available Units" top={0} bottom={20}/>

                            {property.availableUnits && property.availableUnits.length > 0
                                ?   <div id="rental-list">
                                        <Accordion className="rentals-accordion" allowZeroExpanded={true} >
                                            {property.availableUnits.map((unit) => {
                                                return (
                                                    <AccordionItem key={unit.unitNumber} className="rentals-accordion-item">
                                                        <AccordionItemState>
                                                        {({ expanded }) => (
                                                            <AccordionItemHeading className="rentals-accordion-heading">
                                                                <AccordionItemButton className={expanded ? 'accordion-item-active' : 'accordion-item-inactive'}>
                                                                    <p className={expanded ? 'accordion-item-title-active' : 'accordion-item-title-inactive'}>Unit {unit.unitNumber}</p>
                                                                    <p>{getUnitTypeString(unit)}</p>
                                                                    <p className="monthly-rent">{<CurrencyFormat value={unit.askingRent} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <div>{value}/mo</div>} />}</p>
                                                                    <p>Available {new Date(unit.dateAvailable.replace(/-/g, "/")).toLocaleDateString('en-US', {
                                                                        day: 'numeric',
                                                                        month: 'long',
                                                                        year: 'numeric',
                                                                    })}</p>
                                                                    
                                                                    {!expanded 
                                                                        ? <Icon style={{color: 'white'}} id="rental-icon" aria-hidden="false" name='angle down' size="big" /* huge */ /> 
                                                                        : null
                                                                    }
                                    
                                                                </AccordionItemButton>
                                                            </AccordionItemHeading>
                                                        )}
                                                    </AccordionItemState>
                                                        <AccordionItemPanel className="rental-accordion-panel">
                                                                <div id="rental-panel-features">
                                                                <h3>Unit Features</h3>
                                                                    {ReactHtmlParser(property.unitFeatures)}
                                                                </div>
                                                                <div id="rental-panel-lease-terms">
                                                                    <h3>Lease Terms</h3>
                                                                    <ul><CurrencyFormat value={Math.ceil((unit.askingRent * 1.5)/100)*100} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <li>{value} security deposit</li>} /></ul>

                                                                    {/*ReactHtmlParser(unit.leaseTerms)*/}
                                                                </div>
                                                                <div className='rental-panel-buttons'>
                                                                    <Link to="/contact/">Request Information</Link>
                                                                    <a target="_blank" rel="noopener noreferrer" href={`https://shivainvestors.managebuilding.com/Resident/apps/rentalapp/?listingId=${unit.listingId}`}>Apply For This Unit</a>
                                                                </div>
                                                        </AccordionItemPanel>
                                                    </AccordionItem>
                                                )
                                        })}
                            </Accordion>
                                    </div>
                                :   <div id="no-availabilities">No Availabilities...</div>
                            }
                        </div>
            </div>            
        </div>
        : <div className="property-spinner"><Loader type="Circles" color="#ff4c4c" height={80} width={80} /></div>
    }
    </div>
    )
}

export default Property


{/* <Carousel dynamicHeight={true} useKeyboardArrows={true} infiniteLoop={true} swipeable={true} className="property-carousel">
{property.images.map((element) => {
    return (
        <div>
            <img src={element}/>
        </div>
    )
})}
</Carousel> */}