import React, { Component, useEffect, useState } from 'react'

import Loader from 'react-loader-spinner'
import dataProvider from './myDataProvider'
import AvailableUnits from './components/AvailableUnits/AvailableUnits'
import ApartmentSearch from './components/ApartmentSearch/ApartmentSearch'
import CommunityCarousel from './components/CommunityCarousel/CommunityCarousel'


const PropertiesHOC = (props) => {

    const [properties, setProperties] = useState(null)

    useEffect(() => {
        async function fetchData() {
            const propertyData = await dataProvider.getList()
            console.log(propertyData)
            setProperties(propertyData)
        }

        fetchData()
    }, [])


    const getCurrentProperties = (properties) => properties.filter(p => !p.realized)


    const spinnerStyle = {
        height: '300px',
        flexBasis: 50,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        justifySelf: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'white', //#323232
    }
    


    return(
        <div>
            {properties 
            ? 
            <div>
                <AvailableUnits properties={properties}/>
                <CommunityCarousel />
                <ApartmentSearch properties={getCurrentProperties(properties)} searchCity={props.searchCity}/>
            </div>
            : 
            <div>
                <CommunityCarousel />
                <div style={spinnerStyle}><Loader type="Circles" color="#ff4c4c" height={80} width={80} /></div>
            </div>
        }
        </div>
    )
}

export default PropertiesHOC