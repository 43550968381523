import React, { Component, useEffect, useState } from "react";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import {withRouter} from 'react-router-dom';


delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});




const LeafletMap = (props) => {

    const [map, setMap] = useState({
        lat: 40.662855,
        lng: -74.215564,
        zoom: 9.5,
    })
    
    const position = [map.lat, map.lng];

    
    return (
        <Map center={position} zoom={map.zoom} >
          <TileLayer
            attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
            url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
          />
        {props.properties && props.properties.map(property => (
            <Marker
            key={property.id}
            position={ [property.lat, property.lng] }
            onClick={() => {
                console.log("Map icon clicked: ", property);
                props.history.push(`/property/${property.id}`)
            }}
            onMouseOver={(e) => {
                e.target.openPopup();
            }}
            onMouseOut={(e) => {
                e.target.closePopup();
            }}>
                <Popup closeButton={false}>
                    {property.displayName} <br /> {property.city + ", " + property.state}
                </Popup>
            </Marker>
        ))}
        </Map>
    )
}

export default withRouter(LeafletMap)

