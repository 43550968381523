import React, { Component, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import './AvailableUnits.css'

import 'react-image-lightbox/style.css';
import CurrencyFormat from 'react-currency-format';

import ReactList from 'react-list';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
import dataProvider from '../../myDataProvider'


const AvailableUnits = (props) => {
  
    const getAvailableUnits = () => {
        let units = props.properties.reduce((filtered, property) => {
            if (property.availableUnits && property.availableUnits.length > 0) {
                return filtered.concat({property: property, units: property.availableUnits});
            }
            return filtered;
        }, [])

        console.log("Unit reduce: ", units)
        return units
    }


    const unitList = props.properties ? getAvailableUnits() : null


    /* useEffect(() => {
        window.scrollTo(0, 0);

        async function fetchData() {
            const propertyData = await dataProvider.getList()
            console.log(propertyData)
            setProperties(propertyData)
        }

        fetchData()
    }, []) */


    const renderItem = (index, key) => {
        const property = unitList[index].property
        const units = unitList[index].units

        /* console.log("render: ", property) */
        return (
            units.map(unit =>
                    <Link to={{
                        pathname: '/property/' + property.id, // + #rental-list
                    }}
                key={property.id + "::" + unit.unitNumber}
                id="unit-card"
                className='apartment-card'>
                    <div id="unit-card-img" className='apartment-img'>
                        { property.images.length > 0 
                            ? <img src={ property.images[0].src } />
                            : null 
                        }
                    </div>
                    <div className="apartment-text">
                        <p>{`${unit.unitNumber} - ${property.displayName}`}</p>
                        <p>{ property.city + ", " + property.state }</p>


                        <div id="unit-rent" className="monthly-rent">{<CurrencyFormat value={unit.askingRent} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <div>{value}/mo</div>} />}</div>
                        <p id="unit-date-available">Available {new Date(unit.dateAvailable.replace(/-/g, "/")).toLocaleDateString('en-US', {
                            day: 'numeric',
                            month: 'long',
                            year: 'numeric',
                        })}</p>


                        <div className='rental-panel-buttons' id="available-units-buttons">
                                <a target="_blank" rel="noopener noreferrer" onClick={e => e.stopPropagation()} href={`https://shivainvestors.managebuilding.com/Resident/apps/rentalapp/?listingId=${unit.listingId}`}>Apply For This Unit</a>
                        </div>
                        
                </div>
                </Link>
            ) 
        )
    }


    return(
        <>
        <div className="apartment-search" id="search-area">
                <h1>Available Units</h1>
        </div>
        <div className="search-results">
            {/* Display spinner while data fetches */}
            {unitList === null ? 
                <div className="results-spinner"><Loader type="Circles" color="#ff4c4c" height={80} width={80} /></div>
            :
            /* Display message if there are no results from the search */
            ((unitList === undefined || unitList.length == 0) 
                ?   <div className="no-results"><p>No Results...</p></div>
                :
                    <div className="apartments-list" id="unit-list">
                        <div style={{overflow: 'auto', maxHeight: 500}}>
                            <ReactList
                                itemRenderer={renderItem}
                                length={unitList.length}
                                type='simple'
                                initialIndex={0}
                            />
                        </div>
                    </div>
            )}
        </div>
        </>
    )
}

export default AvailableUnits
